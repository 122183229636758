import { Role } from '@alpha/auth-types';
import Axios, { AxiosResponse } from 'axios';
import environmentVars from '../../env.variables';
import { FeatureFlag } from '../../models/featureFlag';
import { TUserAccount, TUserAccounts } from '../../models/user';
import AuthService from '../Auth/auth.service';
import instance, { instanceNoAccountId } from '../Axios/instance';

export class AuthorizationService {
  public static getUserAccounts = async (): Promise<TUserAccounts> => {
    try {
      const sessionUUID = AuthService.getSessionUUIDFromLocalStorage();

      const response: AxiosResponse<TUserAccounts> = await instanceNoAccountId.get(
        'auth/user-accounts',
        {
          headers: {
            'account-id': 'null',
          },
        },
      );
      if (response.status === 200) {
        return response.data;
      }
      throw Error(`There was an issue with axios request:${response.statusText}`);
    } catch (e) {
      throw Error(e);
    }
  }

  public static getFeatureFlagsConfig = async () : Promise<FeatureFlag[]> => {
    try {
      const response: AxiosResponse<FeatureFlag[]> = await instance.get(
        `${environmentVars.REACT_APP_API_URL}auth/feature-flags`,
      );
      if (response.status === 200) {
        return response.data;
      }
      throw Error('could not get feature flags');
    } catch (error) {
      return [];
    }
  }

  public static async getEntitiesForRole(role: Role): Promise<TUserAccount[]> {
    try {
      const response: AxiosResponse<{accounts: TUserAccount[]}> = await instance(
        `/auth/user-accounts-by-role/${role.replace(/\s+/g, '').toUpperCase()}/v1`,
      );
      if (response.status !== 200) {
        throw Error('Unable to get user accounts');
      }
      return response?.data?.accounts;
    } catch (error) {
      return [];
    }
  }
}

export default AuthorizationService;
