import {
  faCogs, faDoorOpen, faExchange, faAngleDown, faAngleRight,
} from '@fortawesome/pro-regular-svg-icons';
import React, {
  memo, useMemo, useState,
} from 'react';
import cls from 'classnames';
import AnnounceKit from 'announcekit-react';
import { NavLink } from 'react-router-dom';
import MenuDropdownItem from 'components/MenuDropDownItem/MenuDropdownItem';
import Menu from 'components/Menu/Menu';
import AuthService from 'services/Auth/auth.service';
import { Flags } from 'react-feature-flags';
import env, { AppUrls } from '../../../env.variables';
import useAuth from '../../../hooks/useAuth';
import routes from '../../../routes.path';
import history from '../../../services/history/browserHistory';
import SwitchAccountDrawer from './SwitchAccountDrawer/SwitchAccountDrawer';
import useSwitchAccount from '../../../hooks/useSwitchAccount';
import useStyles from './index.styles';
import AlphaWhiteLogo from '../../../assets/alpha-full-logo.png';
import megaphone from '../../../assets/megaphone.svg';

const urlToEnvironmentMapping: Record<string, string> = {
  [AppUrls.LIVE]: 'prod',
  [AppUrls.DEVELOPMENT]: 'dev',
  [AppUrls.PREPROD]: 'preprod',
};

const Header = memo(({ isOpen, toggle }: {isOpen: boolean, toggle: () => void}) => {
  const auth = useAuth();
  const announcekitToken = AuthService.getAnnouncekitTokenFromLocalStorage() ?? undefined;
  const { currentAccount, userAccounts } = useSwitchAccount();

  const [showSwitchAccountDrawer, setShowSwitchAccountDrawer] = useState<boolean>(false);
  const [open, setOpen] = useState<null | HTMLElement>(null);

  const classes = useStyles();

  const handleOpenSwitchAccountDrawer = () => setShowSwitchAccountDrawer(true);

  const dropDownItems = useMemo(
    () => [
      {
        id: 'menu-switch-account',
        content: <div>Switch account</div>,
        icon: faExchange,
        arrow: true,
        onClick: () => handleOpenSwitchAccountDrawer(),
      },
      {
        id: 'menu-settings',
        content: <div>Settings</div>,
        icon: faCogs,
        arrow: false,
        underline: true,
        onClick: () => history.push(routes.profile),
      },
      {
        id: 'menu-logout',
        title: <div>Logout</div>,
        content: <div>Logout</div>,
        icon: faDoorOpen,
        onClick: auth.handleSignOut,
      },
    ],
    [auth.handleSignOut],
  );

  return (
    <>
      <header className={classes.root} data-testid="portal-header">
        <div className={classes.wrapperLeft}>
          <div
            role="presentation"
            className={cls(classes.menuButton, isOpen ? 'open' : '')}
            onClick={toggle}
            data-testid="portalHeader-menu-button"
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={classes.wrapperRight}>
          <Menu
            open={Boolean(open)}
            setOpen={setOpen}
            anchorEl={open}
            buttonTitle={auth.userInfo?.Name ? auth.userInfo.Name : ''}
            obscure
            userAccount={(
              <span className="dd-privacy-allow">
                {currentAccount.name}
                {' '}
              </span>
            )}
            menuItemProps={{ style: { fontSize: 14, fontWeight: 500 } }}
            buttonProps={{
              disableRipple: true,
              className: classes.button,
            }}
            menuIconProps={{ icon: faAngleDown, style: { fontSize: 16, height: '50%', marginLeft: 10 } }}
            dropDownIconEndProps={{ icon: faAngleRight, style: { fontSize: 16 } }}
            menuProps={{ anchorOrigin: { vertical: 55, horizontal: 'right' } }}
          >
            {dropDownItems.map((item) => (
              <MenuDropdownItem key={item.id} setOpen={setOpen}>
                {item}
              </MenuDropdownItem>
            ))}
          </Menu>
          {announcekitToken && (
          <Flags authorizedFlags={['announceKit']}>
            <AnnounceKit
              widget="https://announcekit.co/widgets/v2/JOiac"
              data={{
                appVersion: 'v1',
                entities: userAccounts?.map(({ id }) => id).join(','),
                env: `${urlToEnvironmentMapping[window.location.origin] ?? 'dev'}`,
              }}
              user_token={announcekitToken}
              className="!flex items-center"
              widgetStyle={{
                position: 'relative',
                transform: 'translateY(-50%)',
              }}
            >
              <img src={megaphone} alt="MessageIcon" data-testid="megaphone-icon" />
            </AnnounceKit>
          </Flags>
          )}
          <NavLink to="/" className={cls(classes.logoParent)}>
            <div>
              <img alt="logo" src={AlphaWhiteLogo} className={classes.logo} />
            </div>
          </NavLink>
        </div>
      </header>
      <SwitchAccountDrawer
        open={showSwitchAccountDrawer}
        onClose={setShowSwitchAccountDrawer}
      />
    </>
  );
});

export default Header;
