import { Role } from '@alpha/auth-types';
import {
  ApproveBatchDto,
  BatchApprover,
  CountryPaymentPurposesDto,
  PaymentBatchDraftDto,
  PaymentBatchDto,
  PaymentReleasedDto,
  SubmitBatchForApprovalRequest,
} from '@alpha/payments-dtos';
import { scheduledBaseUrl } from 'domain/PaymentsMultiEntity/Dashboard/ScheduledTableContainer/constants';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FileType } from '../../hooks/useReportsPolling';
import { TReportGenerationResponse } from '../../models/currencyAccounts';
import {
  IGetPresignedUrlInstanceParams,
  PaymentRouteEnum,
  TBatchUploadLinkDto,
  TDate,
} from '../../models/payments';
import { TTransactionData } from '../../models/transactions';
import { TUserAccount } from '../../models/user';

import { instance, instanceAccountId } from '../Axios/instance';

export class PaymentsService {
  public static async getPresignedUrl(
    fileName: string,
    accountId?: string,
  ): Promise<TBatchUploadLinkDto> {
    return this.getPresignedUrlAsync(fileName.toLowerCase(), accountId);
  }

  public static async putPaymentFile(
    presignedUrl: string,
    file: File,
    accountId?: string,
  ): Promise<void> {
    return this.putPaymentFileAsync(presignedUrl, file);
  }

  public static async postReportBatchGeneration(
    type: FileType,
    batchId: string,
    accountId?: string,
  ): Promise<string> {
    const response: AxiosResponse<TReportGenerationResponse> = accountId
      ? await instanceAccountId(accountId).get(`/reports/payments/batches/${batchId}?type=${type}`)
      : await instance.get(`/reports/payments/batches/${batchId}?type=${type}`);
    return response.data.executionArn;
  }

  public static async postReportSingleGeneration(
    type: FileType,
    batchId: string,
    accountId?: string,
  ): Promise<string> {
    const response: AxiosResponse<TReportGenerationResponse> = accountId
      ? await instanceAccountId(accountId).get(`/reports/payments/${batchId}?type=${type}`)
      : await instance.get(`/reports/payments/${batchId}?type=${type}`);
    return response.data.executionArn;
  }

  public static async postReportFxGeneration(
    type: FileType,
    tradeId: string,
    accountId?: string,
  ): Promise<string> {
    const response: AxiosResponse<TReportGenerationResponse> = accountId
      ? await instanceAccountId(accountId).get(`/reports/fx/${tradeId}?type=${type}`)
      : await instance.get(`/reports/fx/${tradeId}?type=${type}`);
    return response.data.executionArn;
  }

  public static async getBatchDetails(
    batchId: string,
    routeState: PaymentRouteEnum,
    accountId?: string,
  ): Promise<PaymentBatchDraftDto | PaymentBatchDto> {
    if (
      routeState === PaymentRouteEnum.COMPLETE
      || routeState === PaymentRouteEnum.INPROGRESS
    ) {
      return this.getReleasedBatchDetailsAsync(batchId, accountId);
    }
    return this.getBatchDetailsAsync(batchId, accountId);
  }

  public static async submitAndApprovePayment(
    payload: SubmitBatchForApprovalRequest,
    canApproveOwn: boolean,
    accountId?: string,
  ): Promise<ApproveBatchDto> {
    const response = await this.postSubmitPayment(payload, accountId);

    if (canApproveOwn) {
      return this.postApproveOwnPaymentAsync(payload, accountId);
    }

    return response;
  }

  public static async approvePayment(
    payload: SubmitBatchForApprovalRequest,
    approveOwn: boolean,
    accountId?: string,
  ): Promise<ApproveBatchDto> {
    if (approveOwn) {
      return this.postApproveOwnPaymentAsync(payload, accountId);
    }
    return this.postApprovePaymentAsync(payload, accountId);
  }

  public static async postRejectPayment(
    batchId: string,
    userId?: string,
    batchUploadedById?: string,
    accountId?: string,
  ): Promise<void> {
    if (batchUploadedById === userId) {
      await this.postRejectPaymentOwnAsync(batchId, accountId);
    } else {
      await this.postRejectPaymentAsync(batchId, accountId);
    }
  }

  public static async getPaymentPurpose(
    currencyCode: string,
    accountId: string,
  ): Promise<CountryPaymentPurposesDto> {
    return this.getPaymentPurposeAsync(currencyCode, accountId);
  }

  public static async getPaymentData(
    paymentId: string,
    accountId?: string,
  ): Promise<TTransactionData> {
    const paymentResponse: AxiosResponse<PaymentReleasedDto> = accountId
      ? await instanceAccountId(accountId).get(`/payments/released/${paymentId}/payment`)
      : await instance.get(`/payments/released/${paymentId}/payment`);
    return this.mapPaymentDataToTransactionData(paymentId, paymentResponse.data, accountId);
  }

  public static async getAvailablePaymentDate(
    debitingCurrencyCode: string,
    fundingCurrencyCode: string,
    accountId: string,
  ): Promise<TDate> {
    return this.getAvailablePaymentDateAsync(
      debitingCurrencyCode,
      fundingCurrencyCode,
      accountId,
    );
  }

  public static async postGenerateBatchId(accountId?: string): Promise<{ batchId: string }> {
    const response: AxiosResponse<{ batchId: string }> = accountId
      ? await instanceAccountId(accountId).post(
        '/payments/batches/manual',
      )
      : await instance.post(
        '/payments/batches/manual',
      );
    return response.data;
  }

  public static async postValidateManualPayment(
    batchId: string,
    payments: any,
    accountId?: string,
  ): Promise<number> {
    const response: AxiosResponse<number> = accountId
      ? await instanceAccountId(accountId).post(
        `/payments/batches/submit-manual/${batchId}`,
        payments,
      )
      : await instance.post(
        `/payments/batches/submit-manual/${batchId}`,
        payments,
      );
    return response.status;
  }

  public static async getBatchStatus(batchId: string, accountId?: string): Promise<any> {
    const response = accountId ? await instanceAccountId(accountId).get(`payments/batches/${batchId}/status`)
      : await instance.get(`payments/batches/${batchId}/status`);
    return response.data;
  }

  public static async getBatchInvalid(batchId: string, accountId?: string) {
    const response = accountId ? await instanceAccountId(accountId).get(`/payments/batches/${batchId}/invalid`)
      : await instance.get(`/payments/batches/${batchId}/invalid`);
    return response.data.items;
  }

  public static async getApprovers(batchId: string, accountId?: string) {
    const response: AxiosResponse<BatchApprover[]> = accountId
      ? await instanceAccountId(accountId).get(`/payments/batches/${batchId}/approvers`)
      : await instance.get(`/payments/batches/${batchId}/approvers`);
    return response.data;
  }

  public static async getFxApprovers(batchId: string, accountId?: string) {
    const response: AxiosResponse<BatchApprover[]> = accountId
      ? await instanceAccountId(accountId).get(`/payments/batches/${batchId}/fx-bookers`)
      : await instance.get(`/payments/batches/${batchId}/fx-bookers`);
    return response.data;
  }

  public static async postApproverEmails(
    batchId: string,
    approverIds: string[],
    accountId?: string,
  ): Promise<number> {
    const response: AxiosResponse<number> = accountId
      ? await instanceAccountId(accountId).post(
        `/payments/batches/${batchId}/notify`,
        approverIds,
      )
      : await instance.post(
        `/payments/batches/${batchId}/notify`,
        approverIds,
      );
    return response.status;
  }

  public static async getPaymentErrors(batchId: string): Promise<string> {
    return this.getPaymentErrorsAsync(batchId);
  }

  private static async getPaymentErrorsAsync(batchId: string): Promise<string> {
    const response: AxiosResponse<string> = await instance.get(
      `/payments/batches/${batchId}/payments/invalid/file`,
    );
    return response.data;
  }

  public static async deletePayment(
    batchId: string,
    accountId?: string,
    isScheduled?: boolean,
  ): Promise<number> {
    const url = isScheduled ? `${scheduledBaseUrl}/${batchId}` : `/payments/batches/${batchId}`;
    const response = accountId
      ? await instanceAccountId(accountId).delete(url)
      : await instance.delete(url);
    if (!`${response.status}`.startsWith('20')) {
      throw Error();
    }
    return response.status;
  }

  private static async getPresignedUrlAsync(
    fileName: string,
    accountId?: string,
  ): Promise<TBatchUploadLinkDto> {
    const requestParams: IGetPresignedUrlInstanceParams = {
      method: 'post',
      url: 'payments/batches',
      data: {
        fileName,
      },
    };
    const response: AxiosResponse<TBatchUploadLinkDto> = accountId
      ? await instanceAccountId(accountId)(requestParams) : await instance(
        requestParams,
      );
    return response.data;
  }

  private static async putPaymentFileAsync(
    presignedUrl: string,
    file: File,
  ): Promise<void> {
    return axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
  }

  private static async getBatchDetailsAsync(
    batchId: string,
    accountId?: string,
  ): Promise<PaymentBatchDraftDto> {
    const response = accountId
      ? await instanceAccountId(accountId).get(`/payments/batches/${batchId}`)
      : await instance.get(`/payments/batches/${batchId}`);

    return response.data;
  }

  private static async getReleasedBatchDetailsAsync(
    batchId: string,
    accountId?: string,
  ): Promise<PaymentBatchDto> {
    const response = accountId
      ? await instanceAccountId(accountId).get(`/payments/batches/released/${batchId}`)
      : await instance.get(`/payments/batches/released/${batchId}`);

    return response.data;
  }

  public static async postSubmitPayment(
    payload: SubmitBatchForApprovalRequest,
    accountId?: string,
  ): Promise<ApproveBatchDto> {
    const url = '/payments/batches/submitted';
    try {
      const response = accountId
        ? await instanceAccountId(accountId).post(url, { ...payload })
        : await instance.post(url, { ...payload });
      return response.data;
    } catch (error) {
      throw Error(
        error?.response?.data || 'There was an error submitting your payment',
      );
    }
  }

  private static async postRejectPaymentAsync(
    batchId: string,
    accountId?: string,
  ): Promise<void> {
    const url = `/payments/batches/${batchId}/reject`;
    const response = accountId
      ? await instanceAccountId(accountId).post(url)
      : await instance.post(url);
    if (response.status !== 200 && response.status !== 201) {
      throw Error('There was an error rejecting your payment');
    }
  }

  private static async postRejectPaymentOwnAsync(
    batchId: string,
    accountId?: string,
  ): Promise<void> {
    const url = `/payments/batches/${batchId}/reject-own`;
    const response = accountId
      ? await instanceAccountId(accountId).post(url)
      : await instance.post(url);
    if (response.status !== 200 && response.status !== 201) {
      throw Error('There was an error rejecting your payment');
    }
  }

  private static async postApprovePaymentAsync(
    params: SubmitBatchForApprovalRequest,
    accountId?: string,
  ): Promise<ApproveBatchDto> {
    const request = {
      totp: params.totp,
      approvalRequestId: params.approvalRequestId,
      fraudWarningAcknowledged: params.fraudWarningAcknowledged,
    };
    const url = `/payments/batches/${params.batchId}/approve`;
    const response = accountId
      ? await instanceAccountId(accountId).post(url, request)
      : await instance.post(url, request);
    if (response.status !== 200 && response.status !== 201) {
      throw Error('There was an error approving your payment');
    }
    return response.data;
  }

  public static async postApproveOwnPaymentAsync(
    params: SubmitBatchForApprovalRequest,
    accountId?: string,
  ): Promise<ApproveBatchDto> {
    const request = {
      totp: params.totp,
      approvalRequestId: params.approvalRequestId,
      fraudWarningAcknowledged: params.fraudWarningAcknowledged,
    };

    const url = `/payments/batches/${params.batchId}/approve-own`;
    const response = accountId
      ? await instanceAccountId(accountId).post(url, request)
      : await instance.post(url, request);
    if (response.status !== 200 && response.status !== 201) {
      throw Error('There was an error approving your payment');
    }

    return response.data;
  }

  public static async postReleasePaymentBatchAsync(
    batchId: string,
    accountId?: string,
  ): Promise<void> {
    const request = {
      batchId,
    };
    const url = '/payments/batches/released';
    const response = accountId
      ? await instanceAccountId(accountId).post(url, request)
      : await instance.post(url, request);
    if (response.status !== 200 && response.status !== 201) {
      throw Error(`There was an error releasing your payment batch ${batchId}`);
    }
  }

  private static mapPaymentDataToTransactionData = (
    paymentId: string,
    paymentResponse: PaymentReleasedDto,
    accountId?: string,
  ): TTransactionData => ({
    paymentId,
    creditFriendlyName: paymentResponse.beneficiary.name,
    beneficiary: paymentResponse.beneficiary,
    account: paymentResponse.account,
    valueDate: paymentResponse.releasedDate,
    arrivalDate: paymentResponse.releasedDate,
    reference: paymentResponse.reference,
    creditAmount: paymentResponse.amount,
    creditIban: paymentResponse.beneficiary.iban,
    creditSwift: paymentResponse.beneficiary.swift,
    creditCurrencyCode: paymentResponse.currencyCode,
    accountId,
    fixedCurrency: paymentResponse.instructedCurrency,
    purpose: paymentResponse.purpose,
    debitIban: paymentResponse.debitingAccountIban,
    debitingAccount: paymentResponse.debitingAccount,
    creditingAccount: paymentResponse.creditingAccount,
    approvals: paymentResponse.approvals,
    createdBy: paymentResponse.createdBy,
    createdDate: paymentResponse.createdDate,
  });

  private static async getPaymentPurposeAsync(
    currencyCode: string,
    accountId: string,
  ): Promise<CountryPaymentPurposesDto> {
    const response = accountId
      ? await instanceAccountId(accountId).get(`/payments/country-payment-purposes?countrycode=${currencyCode}&accountId=${accountId}`)
      : await instance.get(`/payments/country-payment-purposes?countrycode=${currencyCode}&accountId=${accountId}`);
    return response.data;
  }

  private static async getAvailablePaymentDateAsync(
    debitingCurrencyCode: string,
    fundingCurrencyCode: string,
    accountId: string,
  ): Promise<TDate> {
    const response = accountId
      ? await instanceAccountId(accountId).get(`/payments/currency-holidays?debitingcurrency=${debitingCurrencyCode}&fundingcurrency=${fundingCurrencyCode}`)
      : await instance.get(`/payments/currency-holidays?debitingcurrency=${debitingCurrencyCode}&fundingcurrency=${fundingCurrencyCode}`);
    return response.data;
  }

  public static async getPaymentInputterEntities(
    config?: AxiosRequestConfig,
  ): Promise<TUserAccount[]> {
    const response: AxiosResponse<{ accounts: TUserAccount[] }> = await instance(
      `/auth/user-accounts-by-role/${Role.PAYMENTS_INPUTTER.replace(/\s+/g, '').toUpperCase()}/v1`,
      config,
    );
    if (response.status !== 200) {
      throw Error('Unable to get user accounts');
    }
    return response?.data?.accounts;
  }

  public static async submitTMDocForPayment(
    paymentFiles: { paymentId: string; files: File[] }[],
    accountId: string,
  ): Promise<void> {
    const request: {
      accountId: string;
      paymentFiles: { paymentId: string; fileNames: string[] }[];
    } = {
      accountId,
      paymentFiles: [],
    };

    request.accountId = accountId;
    request.paymentFiles = [];

    paymentFiles.forEach((paymentFile) => {
      request.paymentFiles.push({
        paymentId: paymentFile.paymentId,
        fileNames: [],
      });

      const fileNames: string[] = [];
      paymentFile.files.forEach((file) => {
        fileNames.push(file.name);
      });

      request.paymentFiles[request.paymentFiles.length - 1].fileNames = fileNames;
    });

    const config: AxiosRequestConfig = {
      method: 'POST',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        'account-id': accountId,
      },
    };

    const getUrlResponse = await instance('payments/tm-doc/submit', config);

    if (getUrlResponse.status !== 200) throw new Error();

    let fileUploadFailed = false;

    const promises = [];
    for (const payment of getUrlResponse.data) {
      for (const fileDetails of payment.Urls) {
        const file = paymentFiles.find((file) => file.paymentId === payment.PaymentId)?.files.find((file) => file.name === fileDetails.FileName);
        if (file) {
          const promise = axios.put(fileDetails.Url, file, {
            headers: {
              'Content-Type': 'application/octet-stream',
            },
          });
          promises.push(promise);
        }
      }
    }

    try {
      const responses = await Promise.all(promises);
      for (const response of responses) {
        if (response.status !== 200) {
          fileUploadFailed = true;
        }
      }
    } catch (e) {
      console.error(`Error uploading files: ${JSON.stringify(e)}`);
      fileUploadFailed = true;
    }
    if (fileUploadFailed) {
      throw new Error();
    }
  }
}

export default PaymentsService;
