import { Role } from '@alpha/auth-types';
import {
  ExecuteTradeRequest,
  ExecuteTradeResultDto,
  IndicativeRateResponse,
  QuoteRequest,
  QuoteResponse,
  SameTradeResponse,
} from '@alpha/fx-dtos';
import {
  IATApprovalResponse, IATDraftResponse, IATRequest, IATQuoteDto, ExecuteQuoteRequest,
} from '@alpha/iat-dtos';
import { IATResponse } from '@alpha/iat-dtos/lib/response/IATResponse';
import { AxiosResponse } from 'axios';
import { getCurrencyDecimalPlaces } from 'utils/currency.helpers';
import { IRateParams } from '../../domain/InterAccountTransfer/DebitingAndCreditingDrawer/DisplayIndicativeRate/Rate.interfaces';
import { TInterAccountTransferForm } from '../../domain/InterAccountTransfer/FormValues';
import { TSearchBaseResponse } from '../../hooks/useSearch';
import {
  TCurrencyAccount,
  TCurrencyAccounts,
} from '../../models/currencyAccounts';
import {
  TIATAccountTypes,
  TIATQuote,
} from '../../models/interAccountTransfer';
import { TTransactionData } from '../../models/transactions';
import { TUserAccount } from '../../models/user';
import instance, { instanceAccountId, instanceNoAccountId } from '../Axios/instance';

export class InterAccountTransferService {
  public static mapiatDraftResponseToTInterAccountTransferForm(
    iatDraftResponse: IATDraftResponse,
  ): TInterAccountTransferForm {
    const formValues = {
      creditingAccount: {
        amount: iatDraftResponse.creditAmount,
        reference: iatDraftResponse.reference,
        currencyAccount: iatDraftResponse.creditCurrencyAccount,
      },
      debitingAccount: {
        amount: iatDraftResponse.debitAmount,
        currencyAccount: iatDraftResponse.debitCurrencyAccount,
      },
      fixedSide: iatDraftResponse.creditAmount
        ? iatDraftResponse.creditCurrencyAccount.currencyCode
        : iatDraftResponse.debitCurrencyAccount.currencyCode,
      iatDraftId: iatDraftResponse.id,
    };

    if (!iatDraftResponse.requiresFx) {
      formValues.creditingAccount.amount = iatDraftResponse.creditAmount
        || iatDraftResponse.debitAmount;
      formValues.debitingAccount.amount = iatDraftResponse.creditAmount
        || iatDraftResponse.debitAmount;
    }

    return formValues;
  }

  public static async submitInterAccountTransfer(
    values: TInterAccountTransferForm,
    accountId?: string,
  ): Promise<string> {
    const iatRequest: IATRequest = {
      reference: values.creditingAccount.reference,
      creditCurrencyAccountId: values.creditingAccount.currencyAccount!.id,
      debitCurrencyAccountId: values.debitingAccount.currencyAccount!.id,
      creditAmount: null,
      debitAmount: null,
    } as IATRequest;

    if (values.fixedSide === values.creditingAccount.currencyAccount?.currencyCode) {
      iatRequest.creditAmount = +values.creditingAccount.amount!;
    } else {
      iatRequest.debitAmount = +values.debitingAccount.amount!;
    }

    return (accountId ? await instanceNoAccountId.post(
      '/iat',
      iatRequest,
      {
        headers: {
          'account-id': accountId,
        },
      },
    )
      : await instance.post('/iat', iatRequest)).data.id;
  }

  public static async getQuoteRequest(
    iatId: string,
    accountId?: string,
  ): Promise<IATQuoteDto> {
    return (
      accountId ? await instanceNoAccountId.post(
        'iat/quotes',
        {
          iatDraftId: iatId,
        },
        {
          headers: {
            'account-id': accountId,
          },
        },
      )
        : await instance.post('/iat/quotes', {
          iatDraftId: iatId,
        })).data;
  }

  public static async executeQuoteRequest(
    instructionId: string,
    accountId?: string,
  ): Promise<{
    tradeFriendlyId: string,
    tradeId: string,
  }> {
    return accountId ? instanceNoAccountId.post(
      '/iat/trades',
      {
        instructionId,
      } as ExecuteQuoteRequest,
      {
        headers: {
          'account-id': accountId,
        },
      },
    )
      : instance.post(
        '/iat/trades', {
          instructionId,
        } as ExecuteQuoteRequest,
      );
  }

  public static async checkSameTrade(
    iatId: string,
    accountId?: string,
  ): Promise<SameTradeResponse[]> {
    return (accountId ? await instanceNoAccountId.post(
      '/iat/trades/check-duplicate-trades',
      {
        iatDraftId: iatId,
      },
      {
        headers: {
          'account-id': accountId,
        },
      },
    )
      : await instance.post('/iat/trades/check-duplicate-trades', {
        iatDraftId: iatId,
      })).data;
  }

  public static async releaseSameCurrencyIAT(
    iatId: string,
    accountId?: string,
  ): Promise<void> {
    return accountId ? instanceNoAccountId.post(
      `/iat/drafts/${iatId}/release`,
      '',
      {
        headers: {
          'account-id': accountId,
        },
      },
    )
      : instance.post(`/iat/drafts/${iatId}/release`);
  }

  public static async approveInterAccountTransfer(
    iatId: string,
    approveOwn: boolean,
    accountId?: string,
  ): Promise<IATApprovalResponse> {
    return (accountId ? await instanceNoAccountId.post(
      `/iat/drafts/${iatId}/${approveOwn ? 'approve-own' : 'approve'}`,
      '',
      {
        headers: {
          'account-id': accountId,
        },
      },
    )
      : await instance.post(`/iat/drafts/${iatId}/${approveOwn ? 'approve-own' : 'approve'}`)).data;
  }

  public static async rejectTransfer(
    iatId: string,
    rejectingOwn: boolean,
    accountId?: string,
  ): Promise<void> {
    return accountId ? instanceNoAccountId.post(
      `/iat/drafts/${iatId}/${rejectingOwn ? 'reject-own' : 'reject'}`,
      '',
      {
        headers: {
          'account-id': accountId,
        },
      },
    )
      : instance.post(`/iat/drafts/${iatId}/${rejectingOwn ? 'reject-own' : 'reject'}`);
  }

  public static async getRate({
    debitingCurrencyCode,
    creditingCurrencyCode,
    fixedSide,
    accountId,
    date,
    amount,
    batchId,
  }: IRateParams): Promise<IndicativeRateResponse> {
    const response = await this.getRateAsync(
      debitingCurrencyCode,
      creditingCurrencyCode,
      fixedSide,
      accountId,
      date,
      amount,
      batchId,
    );
    return { ...response, rate: +response.rate.toFixed(4) };
  }

  public static async postFxQuote(
    sellCurrency: string,
    buyCurrency: string,
    fixedSide: string,
    amount: number,
    debitCurrencyAccountId: string,
    creditCurrencyAccountId: string,
    batchId?: string,
  ): Promise<TIATQuote> {
    const quoteRequest: QuoteRequest = {
      amount,
      sellCurrency,
      buyCurrency,
      fixedSide,
      debitCurrencyAccountId,
      creditCurrencyAccountId,
      batchId,
    };
    const response = await this.postFxQuoteAsync(quoteRequest);
    return {
      ...response,
      valueDate: response.valueDate.toString(),
      rate: +response.rate.toFixed(4),
    };
  }

  public static async postBookFx(
    instructionId: string,
    clientReference?: string,
  ): Promise<ExecuteTradeResultDto & { status: number }> {
    return this.postBookFxAsync({ instructionId, clientReference });
  }

  public static calculateAmount(
    type: TIATAccountTypes,
    amount: number,
    currencyCode?: string,
    rate?: number,
  ): number {
    if (!rate) return amount * 1;
    const currencyDecimals = getCurrencyDecimalPlaces(currencyCode);
    if (type === 'DEBITING') {
      const response = +(amount * rate).toFixed(currencyDecimals);
      return response;
    }
    const response = +(amount / rate).toFixed(currencyDecimals);
    return response;
  }

  private static async postInterAccountTransferAsync(
    values: any, // Was IATRequest, converted to any for the time being
    accountId?: string,
  ): Promise<void> {
    return accountId ? instanceNoAccountId.post('/iat/iat', {
      ...values,
    },
    {
      headers: {
        'account-id': accountId,
      },
    })
      : instance.post('/iat/iat', {
        ...values,
      });
  }

  public static async getTransactionData(
    iatId: string,
    accountId?: string,
  ): Promise<TTransactionData> {
    const iatResponse = accountId ? await this.getIatDataAsyncWithAccountId(iatId, accountId)
      : await this.getIatDataAsync(iatId);
    return this.mapIatDataToTransactionData(iatResponse, accountId);
  }

  public static async getPendingIatsSearch(currencyAccountId: string,
    accountId: string): Promise<TSearchBaseResponse> {
    const response = await instanceAccountId(accountId).get(
      `/iat/search/iat-drafts?debitca=${currencyAccountId}`,
    );
    return response.data;
  }

  public static async getTransactions(
    currencyAccountId: string,
    accountId?: string,
  ): Promise<IATDraftResponse[]> {
    if (accountId) {
      const searchResponse = await this.getPendingIatsSearch(currencyAccountId, accountId);
      return searchResponse.records as IATDraftResponse[];
    }
    const response = await instance.get(
      `/iat/drafts?debitcurrencyaccountid=${currencyAccountId}`,
    );

    return response.data;
  }

  private static async getIatDataAsync(iatId: string): Promise<IATResponse> {
    const response = await instance.get(`/iat/${iatId}`);
    return response.data;
  }

  public static async getIatDataAsyncWithAccountId(iatId: string, accountId: string)
  : Promise<IATResponse> {
    const response: AxiosResponse<IATResponse> = await instanceNoAccountId.get(
      `/iat/${iatId}`,
      {
        headers: {
          'account-id': accountId,
        },
      },
    );
    return response.data;
  }

  private static mapIatDataToTransactionData = (
    iatResponse: IATResponse,
    accountId?: string,
  ): TTransactionData => ({
    debitFriendlyName: iatResponse.debitCurrencyAccount.friendlyName,
    creditFriendlyName: iatResponse.creditCurrencyAccount.friendlyName,
    arrivalDate: iatResponse.arrivalDate,
    reference: iatResponse.reference,
    creditAmount: iatResponse.creditAmount,
    debitAmount: iatResponse.debitAmount,
    debitReference: iatResponse.debitCurrencyAccount.reference,
    creditReference: iatResponse.creditCurrencyAccount.reference,
    debitIban: iatResponse.debitCurrencyAccount.iban,
    creditIban: iatResponse.creditCurrencyAccount.iban,
    debitType: iatResponse.debitCurrencyAccount.type,
    creditType: iatResponse.creditCurrencyAccount.type,
    debitCurrencyCode: iatResponse.debitCurrencyAccount.currencyCode,
    creditCurrencyCode: iatResponse.creditCurrencyAccount.currencyCode,
    accountId,
  });

  public static removeCurrencyAccountById = (
    currencyAccounts: TCurrencyAccounts,
    caId: string | undefined,
  ): TCurrencyAccounts => {
    if (!caId) return [...currencyAccounts];
    return currencyAccounts.filter((ca: TCurrencyAccount) => ca.id !== caId);
  };

  private static async getRateAsync(
    debitingCurrencyCode: string,
    creditingCurrencyCode: string,
    fixedSide?: string,
    accountId?: string,
    date?: string,
    amount: number = 0,
    batchId?: string,
  ): Promise<IndicativeRateResponse> {
    const response: AxiosResponse<IndicativeRateResponse> = accountId
      ? await instanceNoAccountId.get(
        `/fx/indicative-rate?sellcurrency=${debitingCurrencyCode}&buycurrency=${creditingCurrencyCode}&valuedate=${date}&fixedSide=${fixedSide}&amount=${amount}&batchId=${batchId}`,
        {
          headers: {
            'account-id': accountId,
          },
        },
      )
      : await instance.get(
        `/fx/indicative-rate?sellcurrency=${debitingCurrencyCode}&buycurrency=${creditingCurrencyCode}&valuedate=${date}&fixedSide=${fixedSide}&amount=${amount}&batchId=${batchId}`,
      );

    return response.data;
  }

  private static async postFxQuoteAsync(
    quoteRequest: QuoteRequest,
    accountId?: string,
  ): Promise<QuoteResponse> {
    const response: AxiosResponse<QuoteResponse> = accountId ? await instanceNoAccountId.post(
      '/fx/quote',
      quoteRequest,
      {
        headers: {
          'account-id': accountId,
        },
      },
    )
      : await instance.post(
        '/fx/quote',
        quoteRequest,

      );

    return response.data;
  }

  private static async postBookFxAsync(
    executeTradeRequest: ExecuteTradeRequest,
    accountId?: string,
  ): Promise<ExecuteTradeResultDto & { status: number }> {
    const response: AxiosResponse = accountId ? await instanceNoAccountId.post(
      '/fx',
      executeTradeRequest,
      {
        headers: {
          'account-id': accountId,
        },
      },
    )
      : await instance.post(
        '/fx',
        executeTradeRequest,
      );
    return { ...response.data, status: response.status };
  }

  public static async getIatInputterEntities(): Promise<TUserAccount[]> {
    const response: AxiosResponse<{accounts: TUserAccount[]}> = await instance(
      `/auth/user-accounts-by-role/${Role.IAT_INPUTTER.replace(/\s+/g, '').toUpperCase()}/v1`,
    );
    if (response.status !== 200) {
      throw Error('Unable to get user accounts');
    }
    return response?.data?.accounts;
  }

  public static async deletePendingIatById(iatId: string, accountId?: string): Promise<number> {
    return this.deletePendingIatByIdAsync(iatId, accountId);
  }

  public static async deletePendingIatByIdAsync(
    iatId: string, accountId?: string,
  ): Promise<number> {
    const response = accountId
      ? await instanceAccountId(accountId).delete(
        `/iat/drafts/${iatId}`,
      )
      : await instance.delete(
        `/iat/drafts/${iatId}`,
      );

    return response.data;
  }
}

export default InterAccountTransferService;
